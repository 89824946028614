import "./form.css";

import React from "react";
import Heading from "./heading";

const Form = () => {
  return (
    <div className="form">
      <div className="container">
        <Heading headtext="Where Do" colortext="We Located" />
        <iframe
          title="MyLocation"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.096478876717!2d88.3666380750767!3d22.538058379516368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02775e932cef1f%3A0xefd482a70dcf5af0!2sAl%20Madina%20Technical%20Institute!5e0!3m2!1sen!2sin!4v1712339767353!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="fast"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <form action="https://formspree.io/f/xqkorged" method="POST">
          <label>Your Name</label>
          <input
            type="text"
            required
            placeholder="Enter Your Name"
            name="name"
          />
          <label>Email</label>
          <input
            type="email"
            required
            placeholder="Enter Your Email...."
            name="email"
          />
          <label>Phone Number</label>
          <input
            type="number"
            required
            placeholder="Enter Your Phone Number...."
            name="phone"
          />
          <label>Message</label>
          <textarea
            rows="6"
            placeholder="Type Your message here"
            name="message"
          />
          <button className="btn">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
