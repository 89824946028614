import "./Navbar.css";

import React, { useState } from "react";
import { NavLink  } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/Logo.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);

  const changeColor = () =>  {
    if(window.scrollY >=1){
      setColor(true);
    }
    else{
      setColor(false);
    }
  };
  window.addEventListener("scroll",changeColor)

  const handleClick = () => setClick(!click);
  return (
    <div className={!color ? "header" : "header header-bg"}>
      <NavLink exact  activeClassName='is-active'  to="/">
      <img src={logo} className="logo" alt="" />
      </NavLink >
      <ul className={!click ? "nav-menu" : "nav-menu active"}>
        <li>
          <NavLink  style={({ isActive }) => {
 return isActive ? { color: "#fcbf02" } : {};
 }} to="/">Home</NavLink >
        </li>
        <li>
          <NavLink  style={({ isActive }) => {
 return isActive ? { color: "#fcbf02" } : {};
 }} to="/course">Course</NavLink >
        </li>
        <li>
          <NavLink  style={({ isActive }) => {
 return isActive ? { color: "#fcbf02" } : {};
 }} to="/about">About</NavLink >
        </li>
        <li>
          <NavLink  style={({ isActive }) => {
 return isActive ? { color: "#fcbf02" } : {};
 }} to="/contactus">Contact</NavLink >
        </li>
      </ul>
      <div className="hamburger" onClick={handleClick
    }>
        {!click ? (
          <FaBars size={20} style={{ color: "#fff" }} />
        ) : (
          <FaTimes size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
