import "./member.css";
import React from "react";
import img from "../assets/img.jpg";
import img1 from "../assets/img1.jpg";
import man from "../assets/man.png";
import man1 from "../assets/man1.png";
import Heading from "./heading";

const Member = () => {
  return (
    <div className="member">
      <div className="container">
        <Heading headtext="Know Our" colortext="Mentors" />
        <div className="member-wrapper">
          <div className="members">
            <div className="mentor-img">
              <img src={img} alt="true" />
            </div>
            <div className="mentordetails">
              <h2>Ezazul Haque</h2>
              <p> Electrical Engineer / Founder & Managing Director</p>
            </div>

          </div>
          <div className="members">
            <div className="mentor-img ">
              <img src={img1} alt="true" />
            </div>
            <div className="mentordetails">
              <h2>Mohammad Irfan Khan</h2>
              <p> Co-Founder & Director</p>
            </div>

          </div>
          <div className="members">
            <div className="mentor-img">
              <img src={man} alt="true" />
            </div>
            <div className="mentordetails">
              <h2>Dr Khan Mashood Parvez</h2>
              <p>Director Business Development / PHD in Engineering</p>
            </div>

          </div>
          <div className="members">
            <div className="mentor-img">
              <img src={man1} alt="true" />
            </div>
            <div className="mentordetails">
              <h2>Md Afsar</h2>
              <p> Electrical Engineer / Director Technical Training</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Member;
