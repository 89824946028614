import "./hero.css";
import hero from "../assets/hero-bg.png";
import React from "react";
import heroimg from "../assets/heroimg.webp";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${hero})`,
        }}
      >
        <div className="container">
          <div className="content-wrapper">
            <div className="content-left">
              <div className="sub-head">
                <p>
                  Join our Electrical courses and start your career in
                  Electrical World
                </p>{" "}
              </div>
              <h1>
                GET YOURSELF TRAINED AND{" "}
                <span className="color-text">START YOUR CAREER </span> IN THE
                TECHNICAL FIELD.
              </h1>
              <p>
                Learn the latest trend in Electrical Technology and be a part of
                world most prestigious project in abroad countries Gulf as well
                as in India.
              </p>
              <div>
                <Link to="https://wa.me/+916289531085" target="_blank">
                  <button className="hero-btn">Contact US</button>
                </Link>
              </div>
            </div>
            <div className="content-right">
              <img src={heroimg} className="heroimg" alt="true" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
