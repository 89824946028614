import "./pricing.css";
import Learn1 from "../assets/learn1.jpg"
import Learn2 from "../assets/learn2.jpg"
import Learn3 from "../assets/learn3.jpg"
import Learn4 from "../assets/learn4.jpg"

import React from "react";
import { Link } from "react-router-dom";

const PricingCard = () => {
  return (
    <div className="pricing">
      <div className="container">
      <div className="card-container">
        <div className="card">
<img src={Learn1} className="learnimg" alt="true" />
          <p>Operation and maintenance of motors.</p>
          <Link
            to="https://wa.me/+916289531085"
            target="_blank"
            className="btn"
          >
          Contact US
          </Link>
        </div>
        <div className="card">
<img src={Learn2} className="learnimg" alt="true" />
          <p>Operation and maintenance of transformer.</p>
          <Link
            to="https://wa.me/+916289531085"
            target="_blank"
            className="btn"
          >
          Contact US
          </Link>
        </div>
        <div className="card">
          
<img src={Learn3} className="learnimg" alt="true" />
          <p>Operation and maintenance of Electrical Panel.</p>
          <Link to="https://wa.me/+916289531085" target="_blank" className="btn">
          Contact US
          </Link>
        </div>
        <div className="card">
<img src={Learn4} className="learnimg" alt="true" />
          <p>Operation and maintenance of DG and other equipments.</p>
          <Link
            to="https://wa.me/+916289531085"
            target="_blank"
            className="btn"
          >
            Contact US
          </Link>
        </div>
      </div>
      </div>
    </div>
  );
};

export default PricingCard;
