import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../assets/Logo.png";
import React, { useState } from "react";
import whatsapp from "../assets/whatsapp.png";
import fb from "../assets/fb.png";
import youtube from "../assets/youtube.png";
import {
  FaHome,
  FaMapMarkerAlt,
  FaWhatsapp ,
} from "react-icons/fa";
import arrow from "../assets/arrow.png"

function Footer  ()  {
  const [down, setDown] = useState(false);
  const styles = {
    popup:{
      display: down ? "flex" : "none",
    }
  };
  const changeColor = () =>  {
    if(window.scrollY >=1){
      setDown(true);
    }
    else{
      setDown(false);
    }
  };
  window.addEventListener("scroll",changeColor)
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="right">
          <img src={logo} className="logo" alt="true" />
          <p>
            Join our Electrical courses and start your career in Electrical
            World
          </p>
        </div>
        <div className="left">
          <div className="div-wrap">
            <div className="icon">
              {" "}
              <FaHome size={20} style={{ color: "#fff" }} />
            </div>
            <div className="content">
              <p>
              B/41/H/1, Bright Street near Noorie Hotel, 4 No Bridge, Park Circus, Kolkata, West Bengal 700017.
              </p>
            </div>
          </div>
          <div className="div-wrap">
            <div className="icon">
              <FaWhatsapp  size={20} style={{ color: "#fff" }} />
            </div>
            <div className="content">
              <h4>+91 6289531085</h4>
            </div>
          </div>
          <Link to="https://maps.app.goo.gl/pSNWNm28oZixYK4W9" target="_blank" >
          <div className="div-wrap">
            <div className="icon">
              <FaMapMarkerAlt size={20} style={{ color: "#fff" }} />
            </div>
            <div className="content">
              <h4>Al Madina Technical Institute</h4>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="copyright-wrapper">
        <div className="copyright-text">
          <p>
            Copyright © 2023 Al Madina Technical Institute. All Rights Reserved.
          </p>
        </div>
        <div className="social-felx">
          <Link target="_blank" to="https://wa.me/+916289531085">
            <img src={whatsapp} alt="true" className="social-img" />
          </Link>
          <Link target="_blank" to="https://www.facebook.com/almadinatechnicalinstitutekolkatawb">
            <img src={fb} alt="true" className="social-img" />
          </Link>
          <Link target="_blank" to="https://www.youtube.com/@AlMadinaTechnicalInstitute">
            <img src={youtube} alt="true" className="social-img" />
          </Link>
        </div>
      </div>
      <div className="scroll-btn" style={styles.popup}>
          <img  onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }} src={arrow} alt="true" className="arrow" />
        </div>
    </div>
  );
};

export default Footer;
