import React from "react";
import "./course.css";

const Course = () => {
  return (
    <div className="courses">
      <div className="container">
        <div className="heading">
          <h1>Courses</h1>
          <p>
            Join our Electrical courses and start your career in Electircal
            World
          </p>
        </div>
        <div className="course-wrapper">
          <div className="course1">
            <div className="price">
                <div className="amt">Course Amount</div>
                <div className="time">One Time Fee</div>
                <div className="name">Course Name</div>
            </div>
            <div className="details">
                Course Details
            </div>
            <button className="course-btn">Want to join?</button>
          </div>
          <div className="courseactive">
            <div className="course">
            <div className="price">
                <div className="amt">Course Amount</div>
                <div className="time">One Time Fee</div>
                <div className="name">Course Name</div>
            </div>
            <div className="details">
                Course Details
            </div>
            <button className="course-btn">Want to join?</button>
          </div>
          </div>
          <div className="course2">
            <div className="price">
                <div className="amt">Course Amount</div>
                <div className="time">One Time Fee</div>
                <div className="name">Course Name</div>
            </div>
            <div className="details">
                Course Details
            </div>
            <button className="course-btn">Want to join?</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Course;
