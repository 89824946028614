import "./aboutcontent.css";
import Marquee from "react-fast-marquee";
import React from "react";
import aboutimg from "../assets/aboutimg.jpg";
import Heading from "./heading";
import check from "../assets/check.png";
import Mar1 from "../assets/mar1.jpg";
import mar2 from "../assets/mar2.jpg";
import mar3 from "../assets/mar3.jpeg";
import mar4 from "../assets/mar4.webp";

const AboutContent = () => {
  return (
    <>
      <div className="about">
        <div className="wrapper">
          <div className="aboutleft">
            <img className="aboutimg" src={aboutimg} alt="true" />
          </div>
          <div className="aboutright">
            <Heading
              headtext="What you'll explore"
              colortext="within the course"
            />
            <div className="topic-check-wrapper">
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
              <div className="topic-check">
                <img src={check} alt="true" className="tick" />
                <p>Highly Qualified Trainers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-wrapper">
        <Marquee pauseOnClick="true">
          <div className="logo-wrap">
            <img src={Mar1} alt="true" className="logo-img" />
            <img src={mar2} alt="true" className="logo-img" />
            <img src={mar3} alt="true" className="logo-img" />
            <img src={mar4} alt="true" className="logo-img" />
          </div>
          <div className="logo-wrap">
            <img src={Mar1} alt="true" className="logo-img" />
            <img src={mar2} alt="true" className="logo-img" />
            <img src={mar3} alt="true" className="logo-img" />
            <img src={mar4} alt="true" className="logo-img" />
          </div>
          <div className="logo-wrap">
            <img src={Mar1} alt="true" className="logo-img" />
            <img src={mar2} alt="true" className="logo-img" />
            <img src={mar3} alt="true" className="logo-img" />
            <img src={mar4} alt="true" className="logo-img" />
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default AboutContent;
