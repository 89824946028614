import "./heading.css"
import React, {Component} from "react";

export default class Heading extends Component {
    render(){
return (
<div className="heading">
   <h1>{this.props.headtext} <span className="color-text"> {this.props.colortext} </span></h1>
</div>
);
    }
};
